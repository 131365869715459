/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { OrgId } from "@hex/common";
import { all, call, spawn } from "redux-saga/effects";

import { logErrorMsg } from "../util/logging";
import { GetHexFlagFn } from "../util/useHexFlags.js";

import { hexVersionDataConnectionsSaga } from "./sagas/dataConnectionsSaga";
import { graphSaga } from "./sagas/graphSaga";
import { graphV3Saga } from "./sagas/graphV3Saga";
import { mapDatasetSaga } from "./sagas/mapDatasetSaga";
import { secretsSaga } from "./sagas/secretsSaga";

export function* rootSaga(orgId: OrgId, getHexFlag: GetHexFlagFn) {
  const sagas = [
    graphSaga,
    secretsSaga,
    hexVersionDataConnectionsSaga,
    graphV3Saga,
    mapDatasetSaga,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga, orgId, getHexFlag);
            break;
          } catch (e) {
            logErrorMsg(e, "error in reduxs saga");
          }
        }
      }),
    ),
  );
}
