/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ApolloQueryResult, gql } from "@apollo/client";
import { HexVersionId, OrgId, SecretId, SecretName } from "@hex/common";
import { call, put, select, takeEvery } from "redux-saga/effects";

import { client } from "../../client";
import { cellGraphActions } from "../slices/cellGraphSlice";
import {
  HexVersionAction,
  HexVersionSecretMP,
  hexVersionMPActions,
} from "../slices/hexVersionMPSlice";
import { RootState } from "../store";

import {
  GetPossibleSecretsGraphDocument,
  GetPossibleSecretsGraphQuery,
  GetPossibleSecretsGraphQueryVariables,
} from "./secretsSaga.generated";

gql`
  query GetPossibleSecretsGraph($orgId: OrgId!) {
    orgSecrets(orgId: $orgId) {
      id
      name
    }
  }
`;

function* loadHexVersionSecrets(orgId: OrgId, hexVersionId: HexVersionId) {
  const { data }: ApolloQueryResult<GetPossibleSecretsGraphQuery> = yield call(
    client.query,
    {
      query: GetPossibleSecretsGraphDocument,
      variables: {
        orgId,
      } as GetPossibleSecretsGraphQueryVariables,
      fetchPolicy: "no-cache",
    },
  );
  yield put(
    cellGraphActions.updateCachedSecretNames({
      hexVersionId,
      names:
        data?.orgSecrets.reduce<Record<SecretId, SecretName>>((acc, secret) => {
          acc[secret.id] = secret.name;
          return acc;
        }, {}) ?? {},
    }),
  );
}

function* initHexVersionSecrets(
  orgId: OrgId,
  action: HexVersionAction<unknown>,
) {
  yield call(loadHexVersionSecrets, orgId, action.payload.hexVersionId);
}

function* loadHexVersionSecret(
  orgId: OrgId,
  action: HexVersionAction<HexVersionSecretMP>,
) {
  const state: RootState = yield select();
  const { hexVersionId } = action.payload;
  const { secretId } = action.payload.data;
  const existingSecretName =
    state.cellGraph[hexVersionId]?.cachedSecretNames[secretId];
  // Don't reload org secret names if we already have them, since we don't expect them to change
  if (existingSecretName != null) {
    return;
  }
  yield call(loadHexVersionSecrets, orgId, hexVersionId);
}

export function* secretsSaga(orgId: OrgId) {
  yield takeEvery(
    hexVersionMPActions.upsertHexVersionSecret.type,
    loadHexVersionSecret,
    orgId,
  );
  yield takeEvery(
    hexVersionMPActions.initializeFromHexVersionMPData.type,
    initHexVersionSecrets,
    orgId,
  );
}
