/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { MapAreaDataSystem, MapLayer, MapLayerType } from "@hex/common";
import { all, call, put, select, takeEvery } from "redux-saga/effects";

import { getHexMapDatasets } from "../../components/deckgl/dataset/HexMapDatasets";
import { GeoJson } from "../../components/deckgl/geojson/geojsonTypes";
import { convertGeoJsonToFeatures } from "../../components/deckgl/geojson/geojsonUtils";
import {
  mapDatasetActions,
  mapDatasetInternalActions,
} from "../slices/mapDatasetSlice";
import { RootState } from "../store";

function* updateDatasets(
  action: ReturnType<typeof mapDatasetActions.updateDatasets>,
) {
  const { layers } = action.payload;
  const datasets = getDatasetsFromLayers(layers);
  const state: RootState = yield select();
  const existingDatasets = Object.keys(state.mapDataset.statuses);

  const removedDatasets: string[] = [];
  existingDatasets.forEach((ds) => {
    if (!datasets.has(ds)) {
      removedDatasets.push(ds);
    } else {
      datasets.delete(ds);
    }
  });
  const addedDatasets = [...datasets];

  for (const ds of removedDatasets) {
    yield put(mapDatasetInternalActions.removeDataset({ dataset: ds }));
  }

  yield all(addedDatasets.map((ds) => loadDataset(ds)));
}

function* loadDataset(dataset: string) {
  yield put(mapDatasetInternalActions.setDatasetStarted({ dataset }));

  const datasetUrl = getHexMapDatasets()[dataset]?.url;
  if (datasetUrl == null) {
    return;
  }

  const res: Response = yield call(fetch, datasetUrl);

  const contentLength =
    res.headers.get("Content-Length") ?? res.headers.get("content-length") ?? 1;
  const sizeInBytes = contentLength != null ? Number(contentLength) : 0;
  const status = res.status;

  yield put(
    mapDatasetInternalActions.setDatasetHeader({
      dataset,
      status,
      sizeInBytes,
    }),
  );

  if (status !== 200) {
    yield put(
      mapDatasetInternalActions.setDatasetFailed({
        dataset,
        error: new Error(
          `Request returned with status ${status} and error message: ${res.statusText}`,
        ),
      }),
    );
    return;
  }

  if (sizeInBytes === 0) {
    yield put(
      mapDatasetInternalActions.setDatasetFailed({
        dataset,
        error: new Error("There is no content from the requested url"),
      }),
    );
    return;
  }

  const reader = res.body?.getReader();
  let receivedLength = 0;
  const chunks = [];
  while (reader != null && true) {
    const { done, value } = yield reader.read();
    if (done) {
      break;
    }

    chunks.push(value);
    receivedLength += value.length;
    yield put(
      mapDatasetInternalActions.setDatasetStreaming({
        dataset,
        completionRate: receivedLength / sizeInBytes,
      }),
    );
  }

  const chunksAll = new Uint8Array(receivedLength);
  let position = 0;
  for (const chunk of chunks) {
    chunksAll.set(chunk, position);
    position += chunk.length;
  }

  const result = new TextDecoder("utf-8").decode(chunksAll);
  try {
    const parsed = JSON.parse(result);
    yield put(
      mapDatasetInternalActions.setDatasetLoaded({
        dataset,
        data: convertGeoJsonToFeatures(GeoJson.check(parsed)),
      }),
    );
  } catch (error) {
    yield put(
      mapDatasetInternalActions.setDatasetFailed({
        dataset,
        error: error as Error,
      }),
    );
  }
}

function getDatasetsFromLayers(layers: readonly MapLayer[]): Set<string> {
  const datasets = new Set<string>();
  layers.forEach((layer) => {
    if (layer.type === MapLayerType.dataset && layer.data.datasetName != null) {
      datasets.add(layer.data.datasetName);
    } else if (
      layer.type === MapLayerType.area &&
      layer.data.system === MapAreaDataSystem.dataset &&
      layer.data.datasetName != null
    ) {
      datasets.add(layer.data.datasetName);
    }
  });
  return datasets;
}

export function* mapDatasetSaga() {
  yield takeEvery(mapDatasetActions.updateDatasets.type, updateDatasets);
}
