/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ApolloQueryResult } from "@apollo/client";
import { call, put, takeEvery } from "redux-saga/effects";

import { client } from "../../client";
import {
  GetSharedProjectDataConnectionsForSqlCellDocument,
  GetSharedProjectDataConnectionsForSqlCellQuery,
  GetSharedProjectDataConnectionsForSqlCellQueryVariables,
} from "../../components/cell/renderers/sql/SqlLogicCell.generated";
import {
  HexVersionAction,
  hexVersionMPActions,
} from "../slices/hexVersionMPSlice";
import { projectGraphV3Actions } from "../slices/projectGraphV3Slice";

export const REFRESH_SHARED_PROJECT_DATA_CONNECTIONS_ACTION_TYPE =
  "REFRESH_SHARED_PROJECT_DATA_CONNECTIONS";

export type RefreshSharedProjectDataConnectionsAction = HexVersionAction<
  never,
  typeof REFRESH_SHARED_PROJECT_DATA_CONNECTIONS_ACTION_TYPE
>;

// Reuse the SQL cell "data connections" query to potentially hit cache
function* loadHexVersionDataConnections(
  action: RefreshSharedProjectDataConnectionsAction,
) {
  const { hexVersionId } = action.payload;
  const {
    data,
  }: ApolloQueryResult<GetSharedProjectDataConnectionsForSqlCellQuery> =
    yield call(client.query, {
      query: GetSharedProjectDataConnectionsForSqlCellDocument,
      variables: {
        hexVersionId,
      } as GetSharedProjectDataConnectionsForSqlCellQueryVariables,
      fetchPolicy: "no-cache",
    });
  yield put(
    projectGraphV3Actions.updateProjectDataConnections({
      hexVersionId,
      projectDataConnections: data.sharedProjectDataConnections.map(
        (dc) => dc.id,
      ),
    }),
  );
}

export function* hexVersionDataConnectionsSaga() {
  // TODO: call this from everywhere
  yield takeEvery(
    REFRESH_SHARED_PROJECT_DATA_CONNECTIONS_ACTION_TYPE,
    loadHexVersionDataConnections,
  );
  yield takeEvery(
    hexVersionMPActions.initializeFromHexVersionMPData.type,
    loadHexVersionDataConnections,
  );
}
