/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { OrgId, notEmpty } from "@hex/common";
import { debounce, put, select } from "redux-saga/effects";

import { GetHexFlagFn } from "../../util/useHexFlags.js";
import { cellGraphActions } from "../slices/cellGraphSlice";
import {
  HexVersionAction,
  hexVersionMPActions,
} from "../slices/hexVersionMPSlice";
import { RootState } from "../store";

function* refreshGraph(action: HexVersionAction<unknown>) {
  const state: RootState = yield select();
  const hexVersion = state.hexVersionMP[action.payload.hexVersionId];
  if (hexVersion == null) {
    return;
  }
  if (hexVersion.hexVersion.graphV3) {
    return;
  }
  const secretNames = Object.values(hexVersion.secrets.entities)
    .filter((secret) => secret?.deletedDate == null)
    .map((secret) => secret?.name)
    .filter(notEmpty);
  const orgSecretNames = Object.values(hexVersion.hexVersionSecrets.entities)
    .filter((secretLink) => secretLink?.deletedDate == null)
    .map((secretLink) => secretLink?.secretId)
    .filter(notEmpty)
    .map(
      (secretId) =>
        state.cellGraph[action.payload.hexVersionId]?.cachedSecretNames[
          secretId
        ],
    )
    .filter(notEmpty);
  yield put(
    cellGraphActions.updateCellGraph({
      cellContents: hexVersion.cellContents.entities,
      cells: hexVersion.cells.entities,
      hexVersionId: action.payload.hexVersionId,
      secretNames: [...secretNames, ...orgSecretNames],
    }),
  );
}

const HEX_VERSION_GRAPH_ACTIONS = [
  hexVersionMPActions.initializeFromHexVersionMPData.type,
  // TODO: decide if we need to process graph for app too
  hexVersionMPActions.initializeFromHexVersionAppMPData.type,
  hexVersionMPActions.setCellContentsField.type,
  hexVersionMPActions.setCellField.type,
  hexVersionMPActions.upsertCell.type,
  hexVersionMPActions.upsertCellContents.type,
  hexVersionMPActions.moveCell.type,
  hexVersionMPActions.deleteCell.type,
  hexVersionMPActions.restoreCell.type,
  hexVersionMPActions.upsertSecret.type,
  hexVersionMPActions.setSecretField.type,
  hexVersionMPActions.setHexVersionSecretField.type,
  hexVersionMPActions.upsertHexVersionSecret.type,
  cellGraphActions.updateCachedSecretNames.type,
];

export function* graphSaga(_orgId: OrgId, _getHexFlag: GetHexFlagFn) {
  yield debounce(500, HEX_VERSION_GRAPH_ACTIONS, refreshGraph);
}
