/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { BaseGraphSecretV3, OrgId, notEmpty } from "@hex/common";
import { debounce, put, select } from "redux-saga/effects";

import { GetHexFlagFn } from "../../util/useHexFlags.js";
import { cellGraphActions } from "../slices/cellGraphSlice";
import {
  HexVersionAction,
  hexVersionMPActions,
} from "../slices/hexVersionMPSlice";
import { projectGraphV3Actions } from "../slices/projectGraphV3Slice";
import { RootState } from "../store";

function* refreshGraph(
  getHexFlag: GetHexFlagFn,
  action: HexVersionAction<unknown>,
) {
  const state: RootState = yield select();
  const hexVersion = state.hexVersionMP[action.payload.hexVersionId];
  // Don't compute the graph unless we're on hexVersionV3
  if (hexVersion == null || !hexVersion.hexVersion.graphV3) {
    return;
  }
  const projectSecrets = Object.values(hexVersion.secrets.entities)
    .filter((secret) => secret?.deletedDate == null)
    .filter(notEmpty);
  const orgSecrets = Object.values(hexVersion.hexVersionSecrets.entities)
    .filter((secretLink) => secretLink?.deletedDate == null)
    .map((secretLink) => secretLink?.secretId)
    .filter(notEmpty)
    .map((secretId) => ({
      id: secretId,
      name: state.cellGraph[action.payload.hexVersionId]?.cachedSecretNames[
        secretId
      ],
    }))
    .filter((s): s is BaseGraphSecretV3 => s.name != null);
  const secrets: BaseGraphSecretV3[] = [...projectSecrets, ...orgSecrets];

  const linkedDataConnections = Object.values(
    hexVersion.dataConnectionHexVersionLinks.entities,
  )
    .filter((dataConnectionLink) => dataConnectionLink?.deletedDate == null)
    .map((dataConnectionLink) => dataConnectionLink?.dataConnectionId)
    .filter(notEmpty);

  const projectDataConnections = Object.values(
    state.projectGraphV3[action.payload.hexVersionId]?.projectDataConnections
      .entities ?? {},
  ).filter(notEmpty);

  const dataConnections = [...projectDataConnections, ...linkedDataConnections];
  const gridRows = Object.values(hexVersion.gridRows.entities).filter(notEmpty);

  yield put(
    projectGraphV3Actions.updateProjectGraphV3({
      cellContents: hexVersion.cellContents.entities,
      cells: hexVersion.cells.entities,
      hexVersionId: action.payload.hexVersionId,
      secrets,
      dataConnections,
      gridRows,
      forceRedefinitionEdges: getHexFlag("force-redefinition-edges"),
    }),
  );
}

const HEX_VERSION_GRAPH_ACTIONS = [
  hexVersionMPActions.initializeFromHexVersionMPData.type,
  // TODO: decide if we need to process graph for app too
  hexVersionMPActions.initializeFromHexVersionAppMPData.type,
  hexVersionMPActions.setCellContentsField.type,
  hexVersionMPActions.setCellField.type,
  hexVersionMPActions.upsertCell.type,
  hexVersionMPActions.upsertCellContents.type,
  hexVersionMPActions.moveCell.type,
  hexVersionMPActions.upsertSecret.type,
  hexVersionMPActions.deleteCell.type,
  hexVersionMPActions.restoreCell.type,
  hexVersionMPActions.setSecretField.type,
  hexVersionMPActions.setHexVersionSecretField.type,
  hexVersionMPActions.upsertHexVersionSecret.type,
  cellGraphActions.updateCachedSecretNames.type,
  projectGraphV3Actions.updateProjectDataConnections.type,
  // The same as the other graph saga, however also check for hexVersion field updates in case graphV3 gets set
  hexVersionMPActions.setHexVersionField.type,
];

export function* graphV3Saga(_orgId: OrgId, getHexFlag: GetHexFlagFn) {
  yield debounce(500, HEX_VERSION_GRAPH_ACTIONS, refreshGraph, getHexFlag);
}
