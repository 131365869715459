import {
  TypedUseSelectorHook,
  // eslint-disable-next-line no-restricted-imports
  useDispatch as baseUseDispatch,
  // eslint-disable-next-line no-restricted-imports
  useSelector as baseUseSelector,
  // eslint-disable-next-line no-restricted-imports
  useStore as baseUseStore,
} from "react-redux";

import { USE_SELECTOR_LOGGING } from "./config";
import type { AppDispatch, RootState, createStore } from "./store";
import { useSelectorWithLogging } from "./utils/useSelectorWithLogging";

export type RootStore = ReturnType<typeof createStore>;

// App-specific typed versions of the common react-redux hooks
export const useStore: () => RootStore = baseUseStore;
export const useDispatch: () => AppDispatch = baseUseDispatch;
export const useSelector: TypedUseSelectorHook<RootState> =
  USE_SELECTOR_LOGGING && process.env.NODE_ENV === "development"
    ? useSelectorWithLogging
    : baseUseSelector;
